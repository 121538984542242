import { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import LicenseTable from "../../components/LicenseTable";
import LicenseFilters from "../../components/Filters/LicenseFilters";
import Order from "../../components/Order/LicenseOrder";
import LicenseDialog from "../../components/Dialogs/LicenseDialog";
import DeleteLicenseDialog from "../../components/Dialogs/DeleteLicenseDialog";
import { ACTIVE } from "../../utils/constants";
import BasicInfoCard from "../../components/BasicInfoCard";
import { useIntl } from "react-intl";
import {
  useTenant,
  BasicScreenHeader,
  useLoadingGif,
  useLicense,
  useSnackbar,
  TenantIdContext,
  exportToExcel,
  getRequiredDateFormat,
  DarkModeContext,
  useSubTenant,
} from "@datwyler/shared-components";

const defaultFilters = {
  statuses: [ACTIVE],
  startDate: null,
  endDate: null,
  tenants: [],
};

const License = (props: any) => {
  const { tenantId }: any = useContext(TenantIdContext);
  const { fetchTenantById, isfetchTenantByIdLoading, fetchTenantByIdData } =
    useTenant();
  const { fetchSubTenant, fetchSubTenantData, isfetchSubTenantLoading } =
    useSubTenant();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [filters, setFilters] = useState(defaultFilters);
  const [order, setOrder] = useState("startDate");
  const [isLicenseDialogOpen, setIsLicenseDialogOpen] = useState(false);
  const [isDeleteLicenseDialogOpen, setIsDeleteLicenseDialogOpen] =
    useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const {
    fetchSubTenantLicenses,
    isFetchSubTenantLicensesLoading,
    fetchSubTenantLicensesData,
    addLicense,
    isAddLicenseLoading,
    addLicenseResponseData,
    resetAddLicenseData,
    updateLicense,
    isUpdateLicenseLoading,
    updateLicenseResponseData,
    resetUpdateLicenseData,
  } = useLicense();

  const {
    fetchSubTenantLicenses: fetchSubTenantLicensesForExport,
    isFetchSubTenantLicensesLoading: isFetchSubTenantLicensesLoadingForExport,
    fetchSubTenantLicensesData: fetchSubTenantLicensesDataForExport,
  } = useLicense();

  useEffect(() => {
    setIsLoading(
      isfetchTenantByIdLoading ||
        isAddLicenseLoading ||
        isUpdateLicenseLoading ||
        isFetchSubTenantLicensesLoading ||
        isfetchSubTenantLoading ||
        isFetchSubTenantLicensesLoadingForExport ||
        false
    );
  }, [
    isfetchTenantByIdLoading,
    isAddLicenseLoading,
    isUpdateLicenseLoading,
    isFetchSubTenantLicensesLoading,
    isfetchSubTenantLoading,
    isFetchSubTenantLicensesLoadingForExport,
  ]);

  useEffect(() => {
    refetchTenants();
    refetchSubTenantLicenses();
    fetchSubTenant({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        filter: [`status:${ACTIVE}`],
        sort: ["name,asc"],
      },
    });
  }, [tenantId]);

  useEffect(() => {
    refetchSubTenantLicenses();
  }, [filters, page, rowsPerPage, order]);

  useEffect(() => {
    if (fetchSubTenantLicensesData?.subTenantLicenses?.page) {
      setTotalRows(
        fetchSubTenantLicensesData.subTenantLicenses.page.totalElements || 0
      );
    }
  }, [fetchSubTenantLicensesData]);

  useEffect(() => {
    if (addLicenseResponseData?.addLicense?.license) {
      resetAddLicenseData();
      refetchTenants();
      refetchSubTenantLicenses();
      enqueueSnackbar(intl.formatMessage({ id: "create_license_success" }), {
        variant: "success",
      });
      closeLicenseDialog();
    }
  }, [addLicenseResponseData]);

  useEffect(() => {
    if (updateLicenseResponseData?.updateLicense?.license) {
      resetUpdateLicenseData();
      refetchTenants();
      refetchSubTenantLicenses();

      if (isDeleteLicenseDialogOpen) {
        enqueueSnackbar(intl.formatMessage({ id: "delete_license_success" }), {
          variant: "success",
        });
        closeDeleteLicenseDialog();
      } else {
        enqueueSnackbar(intl.formatMessage({ id: "update_license_success" }), {
          variant: "success",
        });
        closeLicenseDialog();
      }
    }
  }, [updateLicenseResponseData]);

  useEffect(() => {
    if (fetchSubTenantLicensesDataForExport?.subTenantLicenses?.licenses) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_license" })
      );
    }
  }, [fetchSubTenantLicensesDataForExport]);

  const refetchTenants = () => {
    fetchTenantById({
      variables: { id: tenantId },
    });
  };

  const getFilters = () => {
    const filtersToSend = [];

    if (filters.startDate && filters.endDate) {
      filtersToSend.push(`endDate>${filters.startDate}`);
      filtersToSend.push(`endDate<${filters.endDate}`);
    }

    if (filters.tenants.length > 0) {
      let tenants = "";
      filters.tenants.forEach((tenantId, index) => {
        if (index === 0) tenants = tenants + `tenantId:` + tenantId;
        else tenants = tenants + `|tenantId:` + tenantId;
      });
      filtersToSend.push(tenants);
    }

    if (filters.statuses.length > 0) {
      let statuses = "";
      filters.statuses.forEach((status, index) => {
        if (index === 0) statuses = statuses + `status:` + status;
        else statuses = statuses + `|status:` + status;
      });
      filtersToSend.push(statuses);
    }

    return filtersToSend;
  };

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "tenant":
          sort = `tenant,asc`;
          break;
        case "startDate":
          sort = `startDate,asc`;
          break;
        case "endDate":
          sort = `endDate,asc`;
          break;
        default:
          sort = `tenant,asc`;
      }
    }

    return sort;
  };

  const refetchSubTenantLicenses = () => {
    const filter = getFilters();
    const sort = getSort();
    fetchSubTenantLicenses({
      variables: {
        tenantId: tenantId,
        page: { number: page, size: rowsPerPage },
        filter: filter,
        sort: [sort],
      },
    });
  };

  const getLowerComponent = () => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Box sx={{ display: "inline-flex" }}>
          <LicenseFilters
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            subTenants={fetchSubTenantData?.subTenants?.tenants}
          />
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            verticalAlign: "top",
            paddingTop: "16px",
          }}
        >
          <Order order={order} setOrder={setOrder} />
        </Box>
      </>
    );
  };

  const closeLicenseDialog = () => {
    setSelectedLicense(null);
    setIsLicenseDialogOpen(false);
  };

  const closeDeleteLicenseDialog = () => {
    setSelectedLicense(null);
    setIsDeleteLicenseDialogOpen(false);
  };

  const fetchDataForExcel = () => {
    const filter = getFilters();
    const sort = getSort();

    fetchSubTenantLicensesForExport({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        filter: filter,
        sort: [sort],
      },
    });
  };

  const getExcelData = () => {
    const excelData =
      fetchSubTenantLicensesDataForExport?.subTenantLicenses?.licenses?.map(
        (data) => {
          const row = {};

          row[intl.formatMessage({ id: "license_number" })] = data.id || "-";
          row[intl.formatMessage({ id: "start_date" })] = data.startDate
            ? getRequiredDateFormat(data.startDate, "DD/MM/YY")
            : "-";
          row[intl.formatMessage({ id: "end_date" })] = data.endDate
            ? getRequiredDateFormat(data.endDate, "DD/MM/YY")
            : "-";

          row[intl.formatMessage({ id: "num_users" })] =
            data.users?.total || "-";
          row[intl.formatMessage({ id: "num_sites" })] =
            data.sites?.total || "-";
          row[intl.formatMessage({ id: "num_devices" })] =
            data.devices?.total || "-";
          row[intl.formatMessage({ id: "tenant" })] = data.tenant?.name || "-";
          row[intl.formatMessage({ id: "curr_user_usage" })] =
            data.users?.current || "-";
          row[intl.formatMessage({ id: "curr_site_usage" })] =
            data.sites?.current || "-";
          row[intl.formatMessage({ id: "curr_device_usage" })] =
            data.devices?.current || "-";
          return row;
        }
      );
    return excelData;
  };

  const getBasicCardInfo = useMemo(() => {
    const tenantById = fetchTenantByIdData?.tenantById;
    const activeLicense =
      tenantById?.license?.status === ACTIVE ? tenantById?.license : null;

    return activeLicense
      ? [
          {
            label: intl.formatMessage({ id: "license_number" }),
            value: activeLicense.id,
          },
          {
            label: intl.formatMessage({ id: "start_date" }),
            value:
              getRequiredDateFormat(activeLicense.startDate, "DD.MM.YYYY") ||
              "",
          },
          {
            label: intl.formatMessage({ id: "end_date" }),
            value:
              getRequiredDateFormat(activeLicense.endDate, "DD.MM.YYYY") || "",
          },
          {
            label: intl.formatMessage({ id: "num_users" }),
            value: activeLicense.users?.total,
          },
          {
            label: intl.formatMessage({ id: "num_sites" }),
            value: activeLicense.sites?.total,
          },
          {
            label: intl.formatMessage({ id: "num_devices" }),
            value: activeLicense.devices?.total,
          },
          {
            label: intl.formatMessage({ id: "tenant" }),
            value: activeLicense.tenant?.name,
          },
          {
            label: intl.formatMessage({ id: "curr_user_usage" }),
            value: activeLicense.users?.current,
          },
          {
            label: intl.formatMessage({ id: "curr_site_usage" }),
            value: activeLicense.sites?.current,
          },
          {
            label: intl.formatMessage({ id: "curr_device_usage" }),
            value: activeLicense.devices?.current,
          },
        ]
      : [];
  }, [fetchTenantByIdData]);

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "menu_license" })}
        addButton={{
          label: intl.formatMessage({ id: "new_license" }),
          onClick: () => {
            setIsLicenseDialogOpen(true);
          },
          width: "150px",
        }}
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: fetchDataForExcel,
        }}
        LowerComponent={getLowerComponent()}
      />
      {getBasicCardInfo.length > 0 && (
        <BasicInfoCard
          title={intl.formatMessage({ id: "license_information" })}
          info={getBasicCardInfo}
        />
      )}
      <LicenseTable
        licenseData={fetchSubTenantLicensesData?.subTenantLicenses?.licenses}
        setSelectedLicense={setSelectedLicense}
        setIsLicenseDialogOpen={setIsLicenseDialogOpen}
        setIsDeleteLicenseDialogOpen={setIsDeleteLicenseDialogOpen}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
      />
      <LicenseDialog
        isOpen={isLicenseDialogOpen}
        closeDialog={closeLicenseDialog}
        selectedLicense={selectedLicense}
        addLicense={addLicense}
        updateLicense={updateLicense}
        tenant={fetchTenantByIdData?.tenantById}
        subTenants={fetchSubTenantData?.subTenants?.tenants}
        licenseData={fetchSubTenantLicensesData?.subTenantLicenses?.licenses}
      />
      <DeleteLicenseDialog
        isOpen={isDeleteLicenseDialogOpen}
        closeDialog={closeDeleteLicenseDialog}
        selectedLicense={selectedLicense}
        updateLicense={updateLicense}
      />
    </Box>
  );
};

export default License;
