import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TenantsTable from "../../components/TenantsTable";
import Filters from "../../components/Filters/TenantsTableFilters";
import Order from "../../components/Order/TenantOrder";
import TenantDialog from "../../components/Dialogs/TenantDialog";
import DeleteTenantDialog from "../../components/Dialogs/DeleteTenantDialog";
import SupporMailManager from "../../components/Dialogs/SupporMailManager";
import { ACTIVE } from "../../utils/constants";
import { useIntl } from "react-intl";
import {
  useTenant,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  useSnackbar,
  TenantIdContext,
  TenantInfo,
  exportToExcel,
  DarkModeContext,
  useSubTenant,
  BasicButton,
  EmailIcon,
} from "@datwyler/shared-components";

const defaultFilters = {
  statuses: [ACTIVE],
};

const TenantManagement = (props: any) => {
  const { tenantId, isMasqAsSubTenant }: any = useContext(TenantIdContext);
  const {
    fetchTenantById,
    isfetchTenantByIdLoading,
    fetchTenantByIdData,
    addTenant,
    isAddTenantLoading,
    addTenantResponseData,
    resetAddTenantData,
    updateTenant,
    isUpdateTenantLoading,
    updateTenantResponseData,
    resetUpdateTenantData,
    updateSupportEmails,
    updateSupportEmailsLoading,
    resetUpdateSupportEmails,
  } = useTenant();
  const { fetchSubTenant, fetchSubTenantData, isfetchSubTenantLoading } =
    useSubTenant();
  const {
    fetchSubTenant: fetchSubTenantForExport,
    fetchSubTenantData: fetchSubTenantDataForExport,
    isfetchSubTenantLoading: isfetchSubTenantLoadingForExport,
  } = useSubTenant();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [filters, setFilters] = useState(defaultFilters);
  const [order, setOrder] = useState("status");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isTenantDialogOpen, setisTenantDialogOpen] = useState(false);
  const [isDeleteTenantDialogOpen, setIsDeleteTenantDialogOpen] =
    useState(false);
  const [isMailManagerOpen, setIsMailManagerOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [supportMailAddresses, setSupportMailAddresses] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    setIsLoading(
      isfetchTenantByIdLoading ||
        isAddTenantLoading ||
        isUpdateTenantLoading ||
        isfetchSubTenantLoading ||
        isfetchSubTenantLoadingForExport ||
        updateSupportEmailsLoading ||
        false
    );
  }, [
    isfetchTenantByIdLoading,
    isAddTenantLoading,
    isUpdateTenantLoading,
    isfetchSubTenantLoading,
    isfetchSubTenantLoadingForExport,
    updateSupportEmailsLoading,
  ]);

  useEffect(() => {
    refetchTenant();
    refetchSubTenants();
    window.dispatchEvent(new Event("tenantsChanged"));
  }, [tenantId]);

  useEffect(() => {
    refetchSubTenants();
  }, [filters, page, rowsPerPage, order]);

  useEffect(() => {
    if (fetchSubTenantData?.subTenants?.page) {
      setTotalRows(fetchSubTenantData.subTenants.page.totalElements || 0);
    }
  }, [fetchSubTenantData]);

  useEffect(() => {
    setInitialSupportEmails();
  }, [fetchTenantByIdData?.tenantById]);

  const refetchTenant = () => {
    fetchTenantById({
      variables: { id: tenantId },
    });
  };

  const setInitialSupportEmails = () => {
    let initialList = fetchTenantByIdData?.tenantById?.supportEmails || [];
    initialList = Array.from(new Set([...initialList]));
    setSupportMailAddresses(initialList);
  };

  const getFilters = () => {
    const filtersToSend = [];

    if (filters.statuses.length > 0) {
      let statuses = "";
      filters.statuses.forEach((status, index) => {
        if (index === 0) statuses = statuses + `status:` + status;
        else statuses = statuses + `|status:` + status;
      });
      filtersToSend.push(statuses);
    }

    return filtersToSend;
  };

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "name":
          sort = `name,asc`;
          break;
        case "status":
          sort = `status,asc`;
          break;
        default:
          sort = `status,asc`;
      }
    }

    return sort;
  };

  const refetchSubTenants = () => {
    const filter = getFilters();
    const sort = getSort();
    fetchSubTenant({
      variables: {
        tenantId: tenantId,
        page: { number: page, size: rowsPerPage },
        filter: filter,
        sort: [sort],
      },
    });
  };

  useEffect(() => {
    if (addTenantResponseData?.addTenant) {
      enqueueSnackbar(intl.formatMessage({ id: "create_new_tenant_success" }), {
        variant: "success",
      });
      resetAddTenantData();
      refetchSubTenants();
      handleCloseDialog();
      window.dispatchEvent(new Event("tenantsChanged"));
    }
  }, [addTenantResponseData]);

  useEffect(() => {
    if (updateTenantResponseData?.updateTenant?.tenant) {
      if (isDeleteTenantDialogOpen) {
        enqueueSnackbar(
          intl.formatMessage({ id: "tenant_deactivated_success" }),
          {
            variant: "success",
          }
        );
        setIsDeleteTenantDialogOpen(false);
      } else {
        enqueueSnackbar(intl.formatMessage({ id: "tenant_updated_success" }), {
          variant: "success",
        });
        handleCloseDialog();
      }
      resetUpdateTenantData();
      refetchSubTenants();
      window.dispatchEvent(new Event("tenantsChanged"));
    }
  }, [updateTenantResponseData]);

  const getLowerComponent = () => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Box sx={{ display: "inline-flex" }}>
          <Filters setFilters={setFilters} defaultFilters={defaultFilters} />
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            verticalAlign: "top",
            paddingTop: "16px",
          }}
        >
          <Order order={order} setOrder={setOrder} />
        </Box>
      </>
    );
  };

  const handleAddTenantDialog = () => {
    setisTenantDialogOpen(!isTenantDialogOpen);
  };

  const handleCloseDialog = () => {
    setSelectedTenant(null);
    setisTenantDialogOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedTenant(null);
    setIsDeleteTenantDialogOpen(false);
  };

  const fetchDataForExcel = () => {
    const filter = getFilters();
    const sort = getSort();

    fetchSubTenantForExport({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        filter: filter,
        sort: [sort],
      },
    });
  };

  useEffect(() => {
    if (fetchSubTenantDataForExport?.subTenants?.tenants) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_tenant" })
      );
    }
  }, [fetchSubTenantDataForExport]);

  const getExcelData = () => {
    const excelData = fetchSubTenantDataForExport?.subTenants?.tenants?.map(
      (data) => {
        const row = {};

        const numberOfLicenses =
          data?.licenses?.filter((license) => license.status === ACTIVE)
            .length || "0";

        row[intl.formatMessage({ id: "tenant_id" })] = data.id || "-";
        row[intl.formatMessage({ id: "name" })] = data.name || "-";
        row[intl.formatMessage({ id: "number_of_licenses" })] =
          numberOfLicenses || "-";
        row[intl.formatMessage({ id: "status" })] = data.status
          ? intl.formatMessage({ id: data.status.toLowerCase() })
          : "-";

        return row;
      }
    );
    return excelData;
  };

  const onSaveSupportEmailsHandler = () => {
    updateSupportEmails({
      variables: {
        input: {
          tenant: { id: tenantId },
          supportEmails: supportMailAddresses || [],
        },
      },
    });

    // fetch the updated tenant details.
    resetUpdateSupportEmails();
    // refetchTenant();
    setIsMailManagerOpen(false);
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Tenants"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "menu_tenant" })}
        addButton={
          !isMasqAsSubTenant
            ? {
                label: intl.formatMessage({ id: "add_tenant" }),
                onClick: handleAddTenantDialog,
                width: "144px",
              }
            : null
        }
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: fetchDataForExcel,
        }}
        MoreActions={() => (
          <div style={{ marginLeft: "24px" }}>
            <BasicButton
              sx={{ height: "44px" }}
              onClick={() => {
                setInitialSupportEmails();
                setIsMailManagerOpen(true);
              }}
              variant="contained"
            >
              <EmailIcon />
              <Typography
                sx={{
                  fontFamily: "NotoSans-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  letterSpacing: "0",
                  lineHeight: "20px",
                  marginLeft: "10px",
                }}
              >
                {intl.formatMessage({ id: "manage_support_email_btn" })}
              </Typography>
            </BasicButton>
          </div>
        )}
        LowerComponent={getLowerComponent()}
      />
      <TenantInfo tenant={fetchTenantByIdData?.tenantById} />
      <TenantsTable
        tenantsData={fetchSubTenantData?.subTenants?.tenants}
        setisTenantDialogOpen={setisTenantDialogOpen}
        setIsDeleteTenantDialogOpen={setIsDeleteTenantDialogOpen}
        setSelectedTenant={setSelectedTenant}
        updateTenant={updateTenant}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
      />
      <TenantDialog
        isOpen={isTenantDialogOpen}
        closeDialog={handleCloseDialog}
        addTenant={addTenant}
        updateTenant={updateTenant}
        selectedTenant={selectedTenant}
      />
      <DeleteTenantDialog
        isOpen={isDeleteTenantDialogOpen}
        closeDialog={handleCloseDeleteDialog}
        updateTenant={updateTenant}
        selectedTenant={selectedTenant}
      />
      <SupporMailManager
        open={isMailManagerOpen}
        onCloseHandler={() => setIsMailManagerOpen(false)}
        supportMailAddresses={supportMailAddresses}
        setSupportMailAddresses={setSupportMailAddresses}
        onSaveHandler={onSaveSupportEmailsHandler}
      />
    </Box>
  );
};

export default TenantManagement;
